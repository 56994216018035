<template>
  <v-card elevation="2">
    <v-card-title>{{ $t("form.news") }}</v-card-title>
    <v-card-text>
      <v-form v-model="isValid">
        <v-text-field
          :label="$t('form.title')"
          outlined
          v-model="postData.title"
          required
          :rules="defaultRules"
        ></v-text-field>
        <!--   <v-textarea
          :label="$t('form.content')"
          outlined
          v-model="postData.html"
          required
          :rules="defaultRules"
        ></v-textarea> -->
        <tiptap-vuetify v-model="postData.html" :extensions="extensions" />
        <v-file-input
          prepend-icon="mdi-camera"
          accept="image/png, image/jpeg, image/bmp"
          v-model="image"
          label="Kiemelt Kép"
          required
          :rules="defaultRules"
        ></v-file-input>
        <file-preview
          v-if="edit"
          :nodelete="true"
          :items="this.item.newsImages"
        ></file-preview>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!edit"
        :disabled="!isValid"
        x-large
        color="secondary"
        @click="submit()"
      >
        {{ $t("form.send") }}
      </v-btn>
      <v-btn x-large @click="reset()">
        {{ $t("form.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";

import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

import { getMultipartHeader, toQueryString } from "../util/functions.js";
import { API_BASE } from "../util/constants";
import FilePreview from "./FilePreview.vue";

export default {
  components: { TiptapVuetify, FilePreview },
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
    item: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showDialog: true,
      isLocked: false,
      isValid: false,
      extensions: [
        Blockquote,
        Link,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [2, 3, 4],
            },
          },
        ],
        Bold,
        Paragraph,
        HardBreak,
      ],
      postData: {
        html: ``,
        title: "",
      },
      image: null,
      defaultRules: [(v) => !!v || "Kötelező"],
      fileRules: [(v) => !!v || "Kötelező"],
    };
  },
  mounted() {
    // console.log(this.$jwt.decode());
    if (this.edit) {
      this.postData.title = this.item.title;
      this.postData.html = this.item.html;
    }
  },
  computed: {},
  methods: {
    submit() {
      let formdata = new FormData();
      formdata.append("images", this.image, this.image.name);
      eventBus.$emit("LOAD_ON");
      const requestOptions = {
        method: "POST",
        headers: getMultipartHeader(localStorage.getItem("token")),
        body: formdata,
        redirect: "follow",
      };
      const query_string = toQueryString(this.postData);
      fetch(`${API_BASE}admin/news?${query_string}`, requestOptions)
        .then((response) => {
          eventBus.$emit("CANCEL_NEWS_ITEM");
          eventBus.$emit("LOAD_OFF");
          eventBus.$emit("REFRESH");
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
        })
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    reset() {
      eventBus.$emit("CANCEL_NEWS_ITEM");
    },
  },
};
</script>

<style scoped lang="scss"></style>
