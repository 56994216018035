<template>
  <div class="NewsView content">
    <v-container v-if="!isEdit">
      <v-card>
        <v-card-text>
          <v-data-table
            v-model="activeItems"
            :single-select="false"
            item-key="name"
            hide-default-footer
            :headers="headers"
            :items="data"
          >
            <template v-slot:item="row">
              <tr>
                <td class="text-xs-right">{{ row.item.title }}</td>
                <td class="text-xs-right">
                  <v-img
                    class="ma-2"
                    max-width="80"
                    max-height="80"
                    @click="showImage(row.item)"
                    :src="getImageUrl(row.item)"
                  ></v-img>
                </td>
                <td class="text-xs-right">
                  {{ row.item.adminUser.lastName }}
                  {{ row.item.adminUser.firstName }}
                </td>
                <td class="text-xs-right">
                  <v-btn icon @click="deleteItem(row.item)" elevation="1">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                  <v-btn icon @click="editItem(row.item)" elevation="1">
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
            <template v-slot:no-data>
              <p>{{ $t("app.noData") }}</p>
            </template>
          </v-data-table>
          <v-pagination
            v-if="hasPagination"
            v-model="currentPage"
            :length="totalPages"
            total-visible="9"
            class="pagination"
          ></v-pagination>
        </v-card-text>
      </v-card>
    </v-container>
    <v-container>
      <div class="f">
        <div class="f center">
          <v-btn
            class="mr-2"
            fab
            color="black"
            elevation="1"
            @click="isNewItem = true"
          >
            <v-icon color="white" large>mdi-plus</v-icon>
          </v-btn>
          <span>{{ $t("app.newItem") }}</span>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="isNewItem" max-width="800">
      <AddNewsItem v-if="isNewItem"></AddNewsItem>
    </v-dialog>
    <v-dialog v-model="isEdit" max-width="800">
      <AddNewsItem v-if="isEdit" :edit="true" :item="activeItem"></AddNewsItem>
    </v-dialog>
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("app.deleteWarning") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            {{ $t("app.cancel") }}
          </v-btn>
          <v-btn @click="finalize()" color="red lighten-1">
            {{ $t("app.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import messageBus from "../messageBus.js";
import eventBus from "../eventBus.js";
import AddNewsItem from "../components/AddNewsItem.vue";
import { IMAGE_BASE } from "../util/constants.js";

export default {
  name: "FridgeList",
  components: { AddNewsItem },
  data() {
    return {
      isImageViewer: false,
      headers: [
        {
          text: this.$t("form.name"),
          sortable: false,
        },
        {
          text: this.$t("app.image"),
          sortable: false,
        },
        {
          text: this.$t("app.createdBy"),
          sortable: false,
        },
        {
          text: this.$t("app.actions"),
          sortable: false,
        },
      ],
      data: [],
      isSchedule: false,
      activeItem: null,
      activeItems: [],
      dialogs: [],
      dialog: false,
      isEdit: false,
      currentPage: 1,
      previewLink: "",
      isNewItem: false,
      hasPagination: false,
      totalPages: 1,
      local: {
        isPending: false,
      },
      filter: {
        keyword: "Keres",
        pending: true,
      },
    };
  },
  created() {
    eventBus.$on("QUIT_EDIT", () => {
      this.isEdit = false;
      this.activeItem = null;
    });
    eventBus.$on("REFRESH", () => {
      this.getList();
      this.isEdit = false;
      this.isNewItem = false;
    });
    eventBus.$on("CANCEL_NEWS_ITEM", () => {
      this.isNewItem = false;
      this.isEdit = false;
      this.activeItem = false;
    });
    eventBus.$on("SAVE_DISPLAY_ITEM", () => {
      this.getList();
    });
  },
  destroyed() {
    eventBus.$off("QUIT_EDIT");
    eventBus.$off("CANCEL_NEWS_ITEM");
    eventBus.$off("SAVE_DISPLAY_ITEM");
    eventBus.$off("REFRESH");
  },
  mounted() {
    this.getList();
  },
  computed: {},
  methods: {
    updateSelected() {
      this.activeItems = [];
      this.data.forEach((item) => {
        if (item.selected === true) {
          this.activeItems.push(item);
        }
      });
    },
    showImage(item) {
      this.previewLink = this.getImageUrl(item);
      this.isImageViewer = true;
    },
    getList() {
      this.$store
        .dispatch("getCollection", {
          end: `admin/news/page/${this.currentPage}/filtered?keyword=`,
          data: null,
        })
        .then((res) => {
          this.totalPages = res.totalPages;
          this.hasPagination = this.totalPages > 1;
          this.data = res.data;
        });
    },
    getImageUrl(item) {
      let name = item.newsImages[0].filename;
      let folder = item.newsImages[0].dirPath.substring(1);
      return `${IMAGE_BASE}${folder}${name}`;
    },
    deleteItem(item) {
      this.activeItem = item;
      this.dialog = true;
    },
    editItem(item) {
      this.activeItem = item;
      this.isEdit = true;
    },
    finalize() {
      this.$store
        .dispatch("removeItem", { end: `admin/news/${this.activeItem.id}` })
        .then((res) => {
          messageBus.$emit("onNewMessage", {
            type: "success",
            label: this.$t("message.success"),
          });
          this.getList();
          this.activeItem = null;
          this.dialog = false;
        });
    },
    saveItem() {},
  },
  watch: {
    currentPage: function () {
      this.getList();
    },
    isEdit: function (val) {
      console.log(val);
      if (!val) this.activeItem = null;
    },
  },
};
</script>
